import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {createusersession, deletenoncomplaintuser, downgradenoncomplaintuser, getusers } from 'backendServices/ApiCalls';
import {Link} from "react-router-dom";
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import { CircularProgress, IconButton, Tooltip,Dialog,DialogTitle,DialogContent,DialogActions,Button, Chip } from '@mui/material';
import { AddTask, Login, PersonRemove, ThumbDown } from '@mui/icons-material';
import Div from '@jumbo/shared/Div/Div';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';




const VISIBLE_FIELDS = ['sr', 'amount', 'status','date'];
const NonCompliant = () => {
  
    const [isLoading, setIsLoading] = useState(false);
    const [isMainLoading, setIsMainLoading] = useState(true);
    const [usersdata,setUsersData]=useState([])
    const [loadingStates, setLoadingStates] = useState({});
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [dguser, setdguser] = useState('')
    const [actionName, setactionName] = useState('')
    const [alertData, setalertData] = React.useState({
        show:false,
        message:"",
        variant:"" 
      })
  let params = {
    status:'noncompliant',
  }
  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleDownGradeUser = (username, action) => {
    // Show the confirmation dialog before proceeding
    setdguser(username)
    setactionName(action)
    handleOpenConfirmation();
  };

  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };
  
  const confirmDownGradeUser = () => {
    let params = {
        "username":dguser
    }
    if(actionName === 'downgrade')
    {
            downgradenoncomplaintuser(params,(response) => {

            if(response?.data?.status === "success") {
                const filteredUsers = usersdata.filter((user) => user.username !== dguser);
                // Update the state with the filtered data
                setUsersData(filteredUsers);
                setalertData({
                    show:true,
                    message:response?.data?.message,
                    variant:"success"
                }) 
                handleCloseConfirmation()
            }
            }, (error) => {
                console.log(error?.response?.data); 
            })
    }
    else if(actionName === 'delete')
    {
            deletenoncomplaintuser(params,(response) => {
            if(response?.data?.status === "success") {
                const filteredUsers = usersdata.filter((user) => user.username !== dguser);
                // Update the state with the filtered data
                setUsersData(filteredUsers);
                setalertData({
                    show:true,
                    message:response?.data?.message,
                    variant:"success"
                }) 
                handleCloseConfirmation()
            }
            }, (error) => {
                console.log(error?.response?.data); 
            }) 
    }

  };

  const getTimeLapseInHours = (createdDate) => {
    const createdDateObj = new Date(createdDate);
    const currentDate = new Date();
    const timeDifferenceInMs = currentDate - createdDateObj;
    const timeDifferenceInHours = Math.floor(timeDifferenceInMs / (1000 * 60 * 60));
    return timeDifferenceInHours;
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr#",
      width: 50,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist
  
    },
  {
    field: "username",
    headerName: "Username",  
    dataGeneratorUniquenessEnabled: true,
    width: 150,
    editable: true,
    groupable: false,
    aggregable: false,
  },
  
  {
    field: "fullname", // New column for Full Name
    headerName: "Full Name",
    dataGeneratorUniquenessEnabled: true,
    width: 200,
    editable: true,
    groupable: false,
    aggregable: false,
  },
  
  {
    field: "email",
    headerName: "email",  
    dataGeneratorUniquenessEnabled: true,
    width: 200,
    editable: true,
    groupable: false,
    aggregable: false,
  },
  
  
  {
    field: "mobile",
    headerName: "Mobile",  
    dataGeneratorUniquenessEnabled: true,
    width: 100,
    editable: true,
    groupable: false,
    aggregable: false,
  },

  
  {
    field: "createdat",
    headerName: "Time Lapse",  
    dataGeneratorUniquenessEnabled: true,
    width: 120,
    editable: true,
    groupable: false,
    aggregable: false,
    renderCell: (params) => {
        const timeLapseInHours = getTimeLapseInHours(params.value);
        return(
            <>
            {
                timeLapseInHours > 72 ? 
                (<Chip label={timeLapseInHours + ' hours'}  color="error"  />) 
                : 
                timeLapseInHours < 72 ? 
                (<Chip label={timeLapseInHours + ' hours'} color="warning"  />) 
                : 
                null
            }
            </>
            )
      },
  },
  
  {
    field: "count_active_referrals",
    headerName: "Active Referrals",  
    dataGeneratorUniquenessEnabled: true,
    width: 200,
    editable: true,
    groupable: false,
    aggregable: false,
  },
  {
    field: "membership",
    headerName: "Membership",  
    dataGeneratorUniquenessEnabled: true,
    width: 120,
    editable: true,
    groupable: false,
    aggregable: false,
  },
  
  
  {
    field: "actions",
    headerName: "Actions",
    width: 120,
    editable: true,
    groupable: false,
    aggregable: false,
    renderCell: (params) => {
      const randomcode = params?.row?.randomcode;
      const username = params?.row?.username;
      const tree = '/binary-tree/' + randomcode;
    
      const isLoading = loadingStates[params.row.userid] || false;
    
      return (
        <>
          <Tooltip title="View Tree">
            <Link to={tree}>
              <ReduceCapacityIcon color="warning" sx={{ fontSize: 20 }} />
            </Link>
          </Tooltip>
          <Tooltip title={"Login to " + username + " account"}>
            <IconButton
              onClick={() => handleCreateUserSession(params.row.userid)}
              disabled={isLoading} // Disable the button while loading is true
            >
              {isLoading ? (
                <CircularProgress color='error' size={20} /> // Show the loading progress
              ) : (
                <Login color="error" />
              )}
            </IconButton>
          </Tooltip>
          {
           params.row.count_active_referrals === "1" ? (
            <Tooltip title={"Downgrade  " + username }>
            <IconButton
              onClick={() => handleDownGradeUser(params.row.username, 'downgrade')}
              disabled={isLoading} // Disable the button while loading is true
            >
              {isLoading ? (
                <CircularProgress color='error' size={20} /> // Show the loading progress
              ) : (
                <ThumbDown color="warning" />
              )}
            </IconButton>
          </Tooltip>
           ) : null
          }
          
          {
            params.row.count_active_referrals === "0" ? (
            <Tooltip title={"Remove "+username+" from Tree" }>
            <IconButton
              onClick={() => handleDownGradeUser(params.row.username, 'delete')}
              disabled={isLoading} // Disable the button while loading is true
            >
              {isLoading ? (
                <CircularProgress color='error' size={20} /> // Show the loading progress
              ) : (
                <PersonRemove color="error" />
              )}
            </IconButton>
            </Tooltip>
            )
            :
            null
          }
          
        </>
      );
    },
    
  },
  
  
  ]
  
  const initialState= {initialState:{
    columns:{
        columnVisibilityModel:{
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
      }
  }
  }
  }
  
  
    const handleCreateUserSession = (userId) => {
      setLoadingStates((prevState) => ({
        ...prevState,
        [userId]: true,
      }));
      setIsLoading(true);
      let params = {
        userId
      }
      createusersession(params,(response) => {

        if(response?.data?.status === "success") {
          window.open(response?.data?.accessurl, '_blank');
            setIsLoading(false);
            setLoadingStates((prevState) => ({
              ...prevState,
              [userId]: false,
            }));
        }
        }, (error) => {
            console.log(error?.response?.data); 
        })

    };


  const UsersData =()=>{
    getusers(params,(response) => {
      if(response?.data?.status === "success") {
          setUsersData(response?.data?.data?.enteries)
          setIsMainLoading(false)
      }
      }, (error) => {
          console.log(error?.response?.data); 
          setIsMainLoading(false)
      })
  }

    
useEffect(()=>{
    UsersData();
},[])



const rows= usersdata

let idCounter = 1; // Initialize the counter

// Assign a unique sequential ID to each row
const rowsWithId = rows.map((row) => ({
  ...row,
  id: idCounter++, // Assign the current counter value and then increment it
  fullname: row.firstname + ' ' + row.lastname,
}));
const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
      color: '#000',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
   
}

if(isMainLoading){
  return  <Div
  sx={{
      display: 'flex',
      minWidth: 0,
      alignItems: 'center',
      alignContent: 'center',
      height: '100%',
  }}
>
  <CircularProgress sx={{m: '-40px auto 0'}}/>
</Div>
}

  return (
    <JumboDemoCard
    title={"Non Compliant Users"}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>
        {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }

      {/* Confirmation Dialog */}
      <Dialog open={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Are you sure you want to {actionName} <b>{dguser}</b>?</DialogTitle>
        <DialogContent>
          {/* Add any additional content or message in the dialog */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            No
          </Button>
          <Button onClick={confirmDownGradeUser} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    <Box sx={{ height: 800, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        rows={rowsWithId}
       
        getRowId={(row) => generateRowId()} 
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[15, 30, 75, 100]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default NonCompliant